import {
  Text,
  Container,
  Anchor,
  MediaQuery,
  Button,
  Box,
  Title,
  Flex,
} from "@mantine/core";
import { isMobile } from "react-device-detect";
import { MdOutlineArrowDownward } from "react-icons/md";
import { Link } from "react-scroll";

// მედია მონიტორინგის სერვისი, პირველი სათაუარი
// ბანერის სათაური. გაიგე რას ამბობენ შენზე
// ნახე აპლიკაცია -> სისტემაში შესვლა
// შეიძინე კივორდი და მომენტალურად გაიგე რას წერს ინტერნეტ მედია შენზე, შენს ბიზნესზე ან შენს კონკურენტზე.
// ცნობილი ადამიანები -> ადამიანები

// სტეფ 1.
// დაიცავი თავი -> ირჩევ კივორდებს რომელებიც შენთვის მნიშვნელოვანია
// ყველა ადამიანისთვის მნიშვნელოვანია მყისიერად გაიგოს თუ რას ამბობენ მასზე, მის ბიზნესზე ან მის კონკურენტზე. ამიტომაც ჩვენი სისტემა ამაში გეხმარებათ.

// სტეფ 2 -> გახსენეს სადმე ? მოგივიდა შეტყობინება
// როგორც კი სადმე გახსენებენ მყისიერად მიიღებთ შეტყობინებას, მეილზე ან SMS-ის სახით.  ეს გაგიადვილებთ თქვენი რეპუტაციის დაცვის პროცესს და შეძლებთ სწრაფ რეაგირებას.

// 3 feature + descriptions

// footer. contact,
const About = () => {
  return (
    <section id="about">
      <Container fluid>
        <div className="about-content">
          <Text mt={15} transform="uppercase" weight={500} color="yellow">
            მედია მონიტორინგის სერვისი
          </Text>

          <Box mt={15}>
            <Text>
              <MediaQuery
                query="(max-width: 768px)"
                styles={{ fontSize: "2.8rem !important" }}
              >
                <Title order={1} className="title">
                  გაიგე რას ამბობენ შენზე
                </Title>
              </MediaQuery>
            </Text>
          </Box>

          <Text c={"dimmed"} size="lg" color="black">
            შეიძინე კივორდი და მომენტალურად გაიგე რას წერს ინტერნეტ მედია შენზე,
            შენს ბიზნესზე ან შენს კონკურენტზე.
            <Anchor
              ml={"sm"}
              href="https://app.mention.ge"
              style={{ textDecoration: "underline" }}
            >
              სისტემაში შესვლა
            </Anchor>
          </Text>

          <Flex
            mt={"sm"}
            justify={"center"}
            direction={isMobile ? "column" : "row"}
            gap={"sm"}
          >
            <Link to="personas" smooth duration={500}>
              <Button
                color="yellow"
                rightIcon={<MdOutlineArrowDownward size={16} />}
                radius="lg"
                size="md"
              >
                ადამიანები
              </Button>
            </Link>

            <Link to="companies" smooth duration={500}>
              <Button variant="default" radius="lg" size="md">
                კომპანიები
              </Button>
            </Link>
          </Flex>
        </div>
      </Container>
    </section>
  );
};

export default About;
