import { Container, Title, Card, Text, SimpleGrid } from "@mantine/core";
import "../Styles/SectionOne.scss";
import { HightLight } from "../utils";
import KeywordHighlight from "./Highlight";
import { FC } from "react";
import { isMobile } from "react-device-detect";

export type HighlightFeedProps = {
  items: HightLight[];
  title: string;
  description: string;
};

const HighlightFeed: FC<HighlightFeedProps> = ({
  title,
  description,
  items,
}) => {
  return (
    <Container>
      <Text color="black">
        <Title order={1}>{title}</Title>
        <Text c={"dimmed"}>{description}</Text>
      </Text>
      <SimpleGrid cols={isMobile ? 1 : 2}>
        {items.map((figure) => (
          <Card mt={"sm"} shadow="xs" key={figure.keywordId}>
            <KeywordHighlight
              avatarImageSrc={figure.avatar}
              keywordId={figure.keywordId}
              name1={figure.name1}
              name2={figure.name2}
            />
          </Card>
        ))}
      </SimpleGrid>
    </Container>
  );
};

export default HighlightFeed;
