import {
  Grid,
  Text,
  Container,
  Title,
  Image,
  Button,
  Box,
} from "@mantine/core";

const SectionThree = () => {
  //const theme = useMantineTheme();

  return (
    <section id="section-three">
      <Container>
        <Grid justify="space-between">
          <Grid.Col xs={6} sm={4} md={4} lg={4}>
            <Image
              src={require("../Images/lime-bicycle-riding.png")}
              alt={"sample2"}
              style={{ width: "100%" }}
            />
          </Grid.Col>
          <Grid.Col xs={6} sm={8} md={8} lg={8}>
            <Box mt={20}>
              <Text color="black">
                <Title order={1}>გახსენეს სადმე ? მოგივიდა შეტყობინება</Title>
                <Text c={"dimmed"}>
                  როგორც კი სადმე გახსენებენ მყისიერად მიიღებთ შეტყობინებას,
                  მეილზე ან SMS-ის სახით. ეს გაგიადვილებთ თქვენი რეპუტაციის
                  დაცვის პროცესს და შეძლებთ სწრაფ რეაგირებას.
                </Text>
              </Text>
            </Box>
            <Button
              onClick={() => window.open("https://app.mention.ge", "_blank")}
              mt={"sm"}
              color="yellow"
            >
              გაიგე მეტი
            </Button>
          </Grid.Col>
        </Grid>
      </Container>
    </section>
  );
};

export default SectionThree;
