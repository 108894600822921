import About from "../Components/About";

import SectionTwo from "../Components/SectionTwo";
import SectionThree from "../Components/SectionThree";
// import SectionFour from "../Components/SectionFour";
// import SectionFive from "../Components/SectionFive";
import HighlightedPoliticalFigures from "../Components/HighlightedPoliticalFigures";
import HighlightedCompanies from "../Components/HighlightedCompanies";

const Content = () => {
  return (
    <>
      <About />

      <HighlightedPoliticalFigures />

      <HighlightedCompanies />

      <SectionTwo />

      <SectionThree />

      {/* <SectionFour /> */}

      {/* <SectionFive /> */}
    </>
  );
};

export default Content;
