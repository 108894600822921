import { Flex, Title, Text, Avatar, Box } from "@mantine/core";
import dayjs from "dayjs";
import { FC } from "react";
import { useGetKeywordsAnalytics } from "../Services/api.service";
import BarChart from "./BarChart";
import { isMobile } from "react-device-detect";

type KeywordHighlightProps = {
  avatarImageSrc: string;
  name1: string;
  name2?: string;
  keywordId: string;
};

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const KeywordHighlight: FC<KeywordHighlightProps> = ({
  avatarImageSrc,
  name1,
  keywordId,
  name2,
}) => {
  const start = dayjs().startOf("week").format(DATE_FORMAT);
  const end = dayjs().endOf("week").format(DATE_FORMAT);
  const { keywordStats } = useGetKeywordsAnalytics(keywordId, start, end);

  const records = keywordStats?.KeywordMatchPerDate
    ? keywordStats.KeywordMatchPerDate.length > 0
      ? keywordStats.KeywordMatchPerDate.map((item) => ({
          field: item.Date,
          total: item.Match,
        }))
      : []
    : [];

  return (
    <Flex gap={"md"} align={"center"} justify={"space-between"}>
      <Flex gap={"md"}>
        <Box>
          <Avatar radius={"md"} size={"xl"} src={avatarImageSrc} />
        </Box>
        <Flex direction={"column"} justify={"center"}>
          <Text c={"dimmed"}>{name1}</Text>
          <Text>{name2}</Text>
        </Flex>
      </Flex>
      <BarChart title="მოხსენიების სიხშირე" data={records} />
    </Flex>
  );
};

export default KeywordHighlight;
