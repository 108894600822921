import { Title, Text, Container, Grid, Image, Button } from "@mantine/core";

const SectionTwo = () => {
  //const theme = useMantineTheme();

  return (
    <section id="section-two">
      <Container mt={40}>
        <Grid justify="space-around">
          <Grid.Col xs={6} sm={8} md={8} lg={8}>
            <div style={{ marginBottom: 20 }}>
              <Text color="black">
                <Title order={1}>
                  ირჩევ კივორდებს რომელებიც შენთვის მნიშვნელოვანია
                </Title>
                <Text c={"dimmed"}>
                  ყველა ადამიანისთვის მნიშვნელოვანია მყისიერად გაიგოს თუ რას
                  ამბობენ მასზე, მის ბიზნესზე ან მის კონკურენტზე. ამიტომაც ჩვენი
                  სისტემა ამაში გეხმარებათ.
                </Text>
              </Text>
            </div>
            <Button
              onClick={() => window.open("https://app.mention.ge", "_blank")}
              color="yellow"
            >
              სისტემაში შესვლა
            </Button>
          </Grid.Col>
          <Grid.Col xs={6} sm={4} md={4} lg={4}>
            <Image
              src={require("../Images/lime-surfing.png")}
              alt={"sample1"}
              style={{ width: "100%" }}
            />
          </Grid.Col>
        </Grid>
      </Container>
    </section>
  );
};

export default SectionTwo;
