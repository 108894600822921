import Content from "./Views/Content";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { MantineProvider } from "@mantine/core";
import { TypographyStylesProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={{
          fontFamily: "BPG WEB 001 Caps",
          headings: {
            fontFamily: "BPG WEB 001 Caps",
          },
        }}
      >
        <TypographyStylesProvider>
          <Header />
          <Content />
          <Footer />
        </TypographyStylesProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
}

export default App;
