import { FC } from "react";
import ApexCharts from "react-apexcharts";

export interface DataItem {
  field: string;
  total: number;
}

export type BarChartProps = {
  data: DataItem[] | [];
  title: string;
};

const BarChart: FC<BarChartProps> = ({ title, data }) => {
  // Series data for ApexCharts
  const series = [
    {
      name: "გამოქვეყნებები",
      data: data.map((item) => item.total),
    },
  ];

  return (
    <ApexCharts
      options={{
        chart: {
          type: "bar",
          width: "100%", // Stretch to 100% width
          toolbar: {
            show: false, // Hide the toolbar for a cleaner look in a small container
          },
        },
        colors: ["#fab005"], // Set a specific color for the bars
        xaxis: {
          categories: data.map((item) => item.field),
          labels: {
            show: true, // Ensure X axis labels are shown
            formatter: (value) => {
              // Format the date as MM.DD
              const date = new Date(value);
              const formattedDate = `${("0" + (date.getMonth() + 1)).slice(
                -2
              )}.${("0" + date.getDate()).slice(-2)}`;
              return formattedDate;
            },
          },
        },
        yaxis: {
          labels: {
            show: false, // Hide Y axis labels since we'll be showing data labels on the bars
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "90%", // Reduces the width of the bars by half compared to previous value
          },
        },
        dataLabels: {
          enabled: true, // Enable data labels to show them on the bars
          // @ts-ignore
          position: "top", // Position labels on top of the bars
          style: {
            colors: ["#fff"], // Set the color of labels, adjust as needed
          },
        },
        legend: {
          show: false, // Hide legend for a cleaner look in a small container
        },
        grid: {
          show: false, // Hide grid for a cleaner look
        },
      }}
      series={series}
      type="bar"
      height={"100%"}
    />
  );
};

export default BarChart;
