import {
  useMantineTheme,
  Container,
  Text,
  Flex,
  Badge,
  Anchor,
} from "@mantine/core";

const Footer = () => {
  const theme = useMantineTheme();

  return (
    <footer
      id="section-footer"
      style={{ backgroundColor: theme.colors.yellow[6] }}
    >
      <Container>
        <Flex
          gap={"xs"}
          align={"center"}
          justify={"center"}
          direction={"column"}
        >
          <Badge size="lg" radius={10} color="yellow">
            Mention.Ge
          </Badge>
          <Text size={"md"} color="white">
            მედია მონიტორინგის სერვისი
          </Text>
          <Anchor
            mt={5}
            href="mailto:info@mention.ge"
            color="dimmed"
            size={"md"}
            style={{ textDecoration: "underline", color: "white" }}
            rel="noreferrer"
          >
            info@mention.ge
          </Anchor>{" "}
        </Flex>
      </Container>
    </footer>
  );
};

export default Footer;
