import { Box } from "@mantine/core";
import "../Styles/SectionOne.scss";
import { POLITICAL_FIGURES, pickRandomThreeHighlights } from "../utils";
import HighlightFeed from "./HighlightFeed";

const HighlightedPoliticalFigures = () => {
  const items = pickRandomThreeHighlights(POLITICAL_FIGURES);
  return (
    <Box id="personas">
      <HighlightFeed
        title="ცნობილი ადამიანები"
        description="მონაცემები ასახავს მოცემულ ადამიანებზე გამოვექყნებების სტატისტიკას კვირის ჭრილში"
        items={items}
      />
    </Box>
  );
};

export default HighlightedPoliticalFigures;
