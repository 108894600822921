import axios from "axios";
import { useQuery } from "react-query";
import { KeywordStats } from "../interface";

const API_URL = "https://api.mention.ge";

export const useGetKeywordsAnalytics = (
  keywordId: string,
  startDate: string,
  endDate: string
) => {
  const { isLoading, error, data } = useQuery(
    `keyword-stats-${keywordId}-${startDate}-${endDate}`,
    () => {
      return axios.get(API_URL + `/api/keyword-stats/partial/${keywordId}`, {
        params: {
          start: startDate,
          end: endDate,
        },
      });
    }
  );
  const keywordStats = data ? (data.data as KeywordStats) : null;
  return {
    keywordStats,
    keywordStatsLoading: isLoading,
    keywordStatsError: error,
  };
};
