import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";
import config from "./config";

ReactGA.initialize(config.REACT_GA_ID);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
