import { Box } from "@mantine/core";
import "../Styles/SectionOne.scss";
import { COMPANIES, pickRandomThreeHighlights } from "../utils";
import HighlightFeed from "./HighlightFeed";

const HighlightedCompanies = () => {
  const items = pickRandomThreeHighlights(COMPANIES);
  return (
    <Box id="companies">
      <HighlightFeed
        title="კომპანიები"
        description="მონაცემები ასახავს მოცემულ კომპანიებზე გამოქვეყნებების სტატისტიკას კვირის ჭრილში"
        items={items}
      />
    </Box>
  );
};

export default HighlightedCompanies;
