import {
  Button,
  Burger,
  Drawer,
  Anchor,
  Text,
  Badge,
  Flex,
} from "@mantine/core";
import React from "react";
import { Link } from "react-scroll";

const Header = () => {
  //const theme = useMantineTheme();
  const [opened, setOpened] = React.useState(false);
  const title = opened ? "Close navigation" : "Open navigation";

  return (
    <header>
      <Flex justify={"space-between"} className="content-desktop">
        <Badge size="lg" radius={10} color="yellow">
          Mention.Ge
        </Badge>
        <Flex align={"center"} gap={"xl"} className="navbar">
          <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            to="section-two"
            smooth
            duration={500}
          >
            <Text c={"dimmed"}>პროექტის შესახებ</Text>
          </Link>
          <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            to="section-footer"
            smooth
            duration={500}
          >
            <Text c={"dimmed"}>კონტაქტი</Text>
          </Link>
          <Button
            color="yellow"
            onClick={() => window.open("https://app.mention.ge", "_blank")}
          >
            სისტემაში შესვლა
          </Button>
        </Flex>
      </Flex>

      <div className="content-mobile">
        <div className="burger-button">
          <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            title={title}
            size="sm"
          />
        </div>

        <Drawer
          transition="rotate-right"
          transitionDuration={250}
          transitionTimingFunction="ease"
          overlayOpacity={0.55}
          position="right"
          closeButtonLabel="Close drawer"
          title="Menu"
          padding="xl"
          opened={opened}
          onClose={() => setOpened(false)}
        >
          <div className="menu">
            {/* <div className="menu-items">
              <div className="menu-item">
                <Link
                  to="section-one"
                  smooth
                  duration={500}
                  onClick={() => setOpened(false)}
                >
                  <Title order={2}>Carousel</Title>
                </Link>
              </div>
              <div className="menu-item">
                <Link
                  to="section-four"
                  smooth
                  duration={500}
                  onClick={() => setOpened(false)}
                >
                  <Title order={2}>Cards</Title>
                </Link>
              </div>
              <div className="menu-item">
                <Link
                  to="section-five"
                  smooth
                  duration={500}
                  onClick={() => setOpened(false)}
                >
                  <Title order={2}>FAQs</Title>
                </Link>
              </div>
            </div> */}

            <div className="menu-items">
              <Text c={"dimmed"}>Contact</Text>

              <Anchor href="mailto:info@mention.ge">info@mention.ge</Anchor>
            </div>
          </div>
        </Drawer>
      </div>
    </header>
  );
};

export default Header;
